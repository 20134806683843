import { FC, useState, ChangeEvent, useEffect, useMemo, useCallback } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import { Option } from '../../Option';
import { SelectListMenu } from '../SelectListMenu';
import { InputProps } from './Input';
import { SearchInput } from './SearchInput';

type InputSuggestionProps = {
  name?: string;
  suggestions: Option<string, string | number>[];
  inputConfig: InputProps;
  className?: string;
  inputStyles?: string;
  instruction?: string;
  isFixed?: boolean;
  onChange?: (newValue: string) => void;
  onPick?: (option: Option<string, string | number>) => void;
  customListRenderer?: FCWithChildren<Option<string, string | number>>;
  disabled?: boolean;
  filterItems?: boolean;
  noMatchMessage?: string;
  take?: number;
};

export const InputSuggestion: FC<InputSuggestionProps> = (props) => {
  const {
    className,
    suggestions,
    inputConfig,
    inputStyles,
    onChange,
    instruction,
    name,
    isFixed = true,
    customListRenderer,
    onPick,
    disabled,
    filterItems = true,
    noMatchMessage,
    take,
  } = props;
  const { value } = inputConfig;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const filteredSuggestions = useMemo(() => {
    const result = filterItems ? suggestions.filter((x) => !value || x.text.toLowerCase().indexOf(value.toLowerCase()) > -1) : suggestions;
    return take ? result.splice(0, take) : result;
  }, [filterItems, suggestions, take, value]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    value && value.length > 0 && !isOpen && setIsOpen(true);
    onChange && onChange(event.target.value);
  };

  const onOptionPicked = (option: Option<string, string | number>) => {
    onPick && onPick(option);
    setSelectedIndex(0);
  };

  useEffect(() => {
    setSelectedIndex((index) => Math.max(0, filteredSuggestions.length <= index ? filteredSuggestions.length - 1 : index));
  }, [filteredSuggestions]);

  const onKeyPress = (event: KeyboardEvent): void => {
    if (!isOpen || !filteredSuggestions.length) return;

    const key = event.key;
    if (key === 'ArrowDown' || (key === 'Tab' && !event.shiftKey)) {
      const newIndex = selectedIndex + 1;
      if (newIndex < filteredSuggestions.length) {
        setSelectedIndex(newIndex);
        event.preventDefault();
      }
    } else if (key === 'ArrowUp' || (key === 'Tab' && event.shiftKey)) {
      const newIndex = selectedIndex - 1;
      if (newIndex >= 0) {
        setSelectedIndex(newIndex);
        event.preventDefault();
      }
    } else if (key === 'Enter') {
      if (filteredSuggestions[selectedIndex]) {
        event.preventDefault();
        onChange && onChange(filteredSuggestions[selectedIndex].text || '');
        onPick && onPick(filteredSuggestions[selectedIndex]);
        setSelectedIndex(0);
        setIsOpen(false);
        (event.target as HTMLInputElement).blur();
      }
    }
  };

  const onBlur = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className={`relative ${className}`}>
      <SelectListMenu
        autoStealFocusOnOpen={false}
        instruction={instruction}
        options={filteredSuggestions}
        isOpen={isOpen}
        onBlur={onBlur}
        onClick={onOptionPicked}
        isFixed={isFixed}
        customListItemRenderer={customListRenderer}
        selectedIndex={selectedIndex}
        noMatchMessage={noMatchMessage}
        highlightedText={value}
      >
        {(triggerProps) => (
          <div {...triggerProps} className={`rounded-dpm-sm flex flex-row justify-between`}>
            <SearchInput
              {...dataAttributeProps(props)}
              {...ariaAttributeProps(inputConfig)}
              {...inputConfig}
              name={name}
              value={value}
              className={inputStyles}
              onChange={onInputChange}
              onFocus={() => setIsOpen(true)}
              onKeyPress={onKeyPress}
              disabled={disabled}
            />
          </div>
        )}
      </SelectListMenu>
    </div>
  );
};
